import React from 'react'
import PropTypes from 'prop-types'
import { useForm, ErrorMessage } from 'react-hook-form'

import { Container, Row, Column } from 'components/elements/Grid'

import Section from './Section'
import Title from './Title'
import { Wrapper, StyledForm, FormField, Label, Input, Textarea, SubmitBtn, ErrorMsg } from './Form'

const emailPattern = new RegExp(
	'^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
)

export default function ContactForm({ data }) {
	const { title, lead } = data
	const { register, handleSubmit, watch, errors } = useForm()

	function onSubmit(formData) {
		console.log(formData)
	}

	function setFilled(name) {
		if (watch(name) && watch(name) !== '') {
			return true
		}
		return false
	}

	return (
		<Section>
			<Container>
				<Row>
					<Title>
						<h2>{title}</h2>
						<p className="lead">{lead}</p>
					</Title>
					<Wrapper>
						<StyledForm onSubmit={handleSubmit(onSubmit)} name="contactForm">
							<Row mx={[0, 0, 0, '100px']}>
								{fields.map(field =>
									field.type === 'textarea' ? (
										<Column>
											<FormField>
												<Textarea
													name={field.name}
													id={field.name}
													ref={register}
													data-filled={setFilled(field.name)}
												/>
												<Label htmlFor="name">{field.label}</Label>
												<ErrorMessage errors={errors} name={field.name} />
											</FormField>
										</Column>
									) : (
										<Column width={[1, 1, 1 / 2]}>
											<FormField>
												<Input
													type={field.type}
													name={field.name}
													id={field.name}
													ref={register(field.validation)}
													data-filled={setFilled(field.name)}
												/>
												<Label htmlFor="name">{field.label}</Label>
												<ErrorMessage errors={errors} name={field.name} />
											</FormField>
										</Column>
									),
								)}
								<Column>
									<SubmitBtn>Wyślij</SubmitBtn>
								</Column>
							</Row>
						</StyledForm>
					</Wrapper>
				</Row>
			</Container>
		</Section>
	)
}

ContactForm.defaultProps = {
	data: {},
}

ContactForm.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		lead: PropTypes.string,
	}),
}

const fields = [
	{
		name: 'name',
		type: 'text',
		label: 'Imię i nazwisko',
		validation: {
			required: <ErrorMsg>To pole jest wymagane !</ErrorMsg>,
			minLength: {
				value: 2,
				message: <ErrorMsg>To pole musi zawierać min. 2 znaki</ErrorMsg>,
			},
		},
	},
	{
		name: 'email',
		type: 'email',
		label: 'Email',
		validation: {
			required: <ErrorMsg>To pole jest wymagane !</ErrorMsg>,
			maxLength: {
				value: 30,
				message: <ErrorMsg>Zbyt wiele znaków - max 30.</ErrorMsg>,
			},
			pattern: {
				value: emailPattern,
				message: <ErrorMsg>Email</ErrorMsg>,
			},
		},
	},
	{
		name: 'phone',
		type: 'number',
		label: 'Telefon',
	},
	{
		name: 'company',
		type: 'text',
		label: 'Firma',
	},
	{
		name: 'message',
		type: 'textarea',
		label: 'Wiadomosć',
	},
]
