import React from 'react'
import { ThemeProvider } from 'emotion-theming'

import Seo from 'components/common/Seo'
import Header from 'components/common/Header'
import Main from 'components/common/Main'
import Footer from 'components/common/Footer'
import theme from 'utils/theme'

import GlobalStyles from './GlobalStyles'

export default function Layout({ footerData, headerData, seo, children }) {
	return (
		<ThemeProvider theme={theme}>
			<Seo data={seo} />
			<GlobalStyles />
			<Header data={headerData} />
			<Main>{children}</Main>
			<Footer data={footerData} />
		</ThemeProvider>
	)
}
