import styled from '@emotion/styled'

import { Column } from 'components/elements/Grid'
import media from 'utils/media'

function setOrder(type) {
	switch (type) {
		case 'left':
			return 2
		case 'right':
			return 1
		default:
			return 1
	}
}

const Image = styled(Column)`
	margin-bottom: 30px;

	.gatsby-image-wrapper {
		max-height: 500px;

		img {
			margin: 0;
		}
	}

	${({ type }) => media.greaterThan('lg')`
		order: ${setOrder(type)};
		margin-bottom: 0;

		.gatsby-image-wrapper {
			max-height: 100%;
		}
	`};
`

export default Image
