import styled from '@emotion/styled';

import media from 'utils/media';

const Section = styled.section`
	padding: 30px 0;

	${media.greaterThan('md')`
		padding: 50px 0;
	`};
`;

export default Section;
