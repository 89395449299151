import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, withPrefix, graphql } from 'gatsby'
import Helmet from 'react-helmet'

export default function Seo({ data, pathname }) {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            name
            author
            siteUrl
            title
            description
            seo {
              separator
              og {
                fbAppId
                type
                title
                description
                image
              }
              twitter {
                creator
                card
              }
            }
          }
        }
      }
    `,
	)

	const { title: SBtitle, description: SBdescription, og_image: SBogImage } = data
	const {
		name,
		siteUrl,
		title,
		description,
		author,
		seo: { separator, og, twitter },
	} = site.siteMetadata || {}

	return (
		<Helmet>
			<title>{SBtitle ? `${SBtitle} ${separator} ${name}` : title}</title>
			<meta
				name="description"
				content={SBdescription || description}
			/>
			<meta
				name="image"
				content={siteUrl + withPrefix(og.image)}
			/>

			{/* OpenGraph tags */}
			<meta
				property="fb:app_id"
				content={og.fbAppId}
			/>
			<meta
				property="og:url"
				content={siteUrl + pathname}
			/>
			<meta
				property="og:type"
				content={og.type}
			/>
			<meta
				property="og:title"
				content={SBtitle || og.title}
			/>
			<meta
				property="og:description"
				content={SBdescription || og.description || description}
			/>
			<meta
				property="og:image"
				content={SBogImage || siteUrl + withPrefix(og.image)}
			/>

			{/* Twitter Card tags */}
			<meta
				name="twitter:card"
				content={twitter.card || 'summary'}
			/>
			<meta
				name="twitter:creator"
				content={twitter.creator || author}
			/>
			<meta
				name="twitter:title"
				content={title}
			/>
			<meta
				name="twitter:description"
				content={SBdescription || description}
			/>
			<meta
				name="twitter:image"
				content={siteUrl + withPrefix(og.image)}
			/>
		</Helmet>
	)
}

Seo.defaultProps = {
	data: {},
	pathname: '/',
}

Seo.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		og_image: PropTypes.string,
		og_title: PropTypes.string,
		og_description: PropTypes.string,
	}),
	pathname: PropTypes.string,
}
