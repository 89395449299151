import React from 'react'
import PropTypes from 'prop-types'

import { Container, Row, Column } from 'components/elements/Grid'
import Markdown from 'components/elements/Markdown'

import Section from './Section'
import Title from './Title'
import Content from './Content'

export default function Text({ data }) {
	const { heading, lead, content } = data
	return (
		<Section>
			<Container>
				<Row justifyContent="center">
					<Column width={[1, 1, '80%']}>
						<Title>
							<h2>{heading}</h2>
							<p className="lead">{lead}</p>
						</Title>
						{content && (
							<Content>
								<Markdown>{content}</Markdown>
							</Content>
						)}
					</Column>
				</Row>
			</Container>
		</Section>
	)
}

Text.defaultProps = {
	data: {},
}

Text.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		lead: PropTypes.string,
		content: PropTypes.string,
	}),
}
