import React from 'react'
import Img from 'gatsby-image'

import { getFixedGatsbyImage, getFluidGatsbyImage } from 'gatsby-storyblok-image'

export default function ImageComponent({ fluid, fixed, data, options = {}, ...rest }) {
	const fixedProps =
		fixed && data
			? getFixedGatsbyImage(data, {
					quality: 100,
					smartCrop: true,
					downloadLocal: true,
					...options,
			  })
			: null

	const fluidProps =
		fluid && data
			? getFluidGatsbyImage(data, {
					quality: 80,
					smartCrop: false,
					downloadLocal: true,
					...options,
			  })
			: null

	return fixedProps ? (
		<Img
			fixed={fixedProps}
			objectFit="cover"
			objectPosition="50% 50%"
			alt={data.alt_tag || ''}
			{...rest}
		/>
	) : fluidProps ? (
		<Img
			fluid={fluidProps}
			objectFit="cover"
			objectPosition="50% 50%"
			alt={data.alt_tag || ''}
			{...rest}
		/>
	) : null
}
