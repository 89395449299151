import React from 'react'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'

import { Row, Column } from 'components/elements/Grid'
import Markdown from 'components/elements/Markdown'
import { formatDate } from 'utils/helpers'
import media from 'utils/media'

export default function RecentPosts({ posts }) {
	return (
		<Wrapper>
			<h2>Najnowsze aktualnosci</h2>
			<Row
				flexWrap={['wrap', 'wrap', 'wrap', 'no wrap']}
				justifyContent={[null, null, 'center', 'flex-start']}
			>
				{posts.map(({ content }) => {
					return (
						<Post key={content._uid} width={[1, 1, 2 / 3, 1 / 3]}>
							<div>
								{!isEmpty(content.excerpt) && <Markdown>{content.excerpt}</Markdown>}
								<StyledInfo>
									<span>{formatDate(content.date)}</span>
									<span>{content.author}</span>
								</StyledInfo>
							</div>
						</Post>
					)
				})}
			</Row>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	margin-top: 100px;
`
const Post = styled(Column)`
	margin: 30px 0;

	& > div {
		border-radius: 20px;
		padding: 20px;
		box-shadow: rgba(0, 124, 137, 0.2) 0px 0px 20px -3px;
		transition: all 0.2s ease-in 0s;
	}

	&:hover {
		& > div {
			box-shadow: rgba(0, 124, 137, 0.6) 0px 0px 33px -7px;
		}
	}

	${media.greaterThan('sm')`
		text-align: center;
	`};

	${media.greaterThan('md')`
		& > div {
			border-radius: 10px;
			height: 320px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			h4 {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
			}

			p {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
			}
		}
	`};
`

const StyledInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: rgba(0, 124, 137, 0.4);
	font-weight: bold;

	${media.greaterThan('sm')`
		width: 60%;
		margin: 0 auto;
	`};

	${media.greaterThan('md')`
		width: 100%;
		margin: 0;
	`};
`
