import React from 'react'
import styled from '@emotion/styled'

import { Column } from 'components/elements/Grid'
import { ReactComponent as WarningIco } from 'assets/svg/warning.svg'

export const Wrapper = styled(Column)``

export const StyledForm = styled.form``

export const FormField = styled.div`
	position: relative;
	padding-bottom: 17px;
	margin-top: 50px;
`

export const Label = styled.label`
	position: absolute;
	top: 10px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1;
	letter-spacing: 1.5px;
	color: ${({ theme }) => theme.colors.footerLink};
	transition: top 0.3s cubic-bezier(0.77, 0, 0.175, 1);
	cursor: text;
`

export const Input = styled.input`
	display: block;
	width: 100%;
	padding: 10px 0;
	border: none;
	border-bottom: 2px solid ${({ theme }) => theme.colors.footerLink};
	line-height: 1;
	background: transparent;
	border-radius: 0;
	appearance: none;
	color: ${({ theme }) => theme.colors.text};
	font-family: inherit;
	letter-spacing: 0.5px;
	outline: 0;
	transition: border-bottom 0.2s cubic-bezier(0.77, 0, 0.175, 1);
	cursor: pointer;

	&.isError {
		border-bottom: 1px solid ${({ theme }) => theme.colors.error};
	}

	&:focus + ${Label}, &[data-filled='true'] + ${Label} {
		font-size: 75%;
		top: -10px;
	}
`

export const Textarea = styled.textarea`
	display: block;
	width: 100%;
	height: 100px;
	padding: 5px 0;
	border: none;
	border-bottom: 2px solid ${({ theme }) => theme.colors.footerLink};
	color: ${({ theme }) => theme.colors.text};
	background: transparent;
	border-radius: 0;
	appearance: none;
	resize: none;
	font-family: inherit;
	letter-spacing: 0.5px;
	outline: 0;
	transition: border-bottom 0.2s cubic-bezier(0.77, 0, 0.175, 1);
	cursor: pointer;

	&.isError {
		border-bottom: 1px solid ${({ theme }) => theme.colors.error};
	}

	&:focus + ${Label}, &[data-filled='true'] + ${Label} {
		font-size: 75%;
		top: -20px;
	}
`

export const SubmitBtn = styled.button`
	padding: 20px;
	background-color: white;
	color: ${({ theme }) => theme.colors.footerLink};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	margin: 30px auto 0;
	transition: 0.3s ease-in-out;
	border: 2px solid ${({ theme }) => theme.colors.footerLink};
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;

	&:hover {
		background-color: ${({ theme }) => theme.colors.footerLink};
		color: white;
	}
`

const StyledError = styled.div`
	display: flex;
	align-items: center;
	padding: 5px 0;

	p {
		margin: 0;
		color: red;
		font-weight: bold;
		line-height: 1;
	}

	svg {
		fill: red;
		display: inline-block;
		width: 16px;
		margin-right: 10px;
	}
`
export const ErrorMsg = ({ children }) => (
	<StyledError>
		<WarningIco />
		<p>{children}</p>
	</StyledError>
)
