import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import { Container, Row, Column } from 'components/elements/Grid'

import Section from './Section'
import Title from './Title'
import NewsList from './NewsList'

export default function ActualNews({ data }) {
	const { heading, lead, news_category } = data
	const { news } = useStaticQuery(
		graphql`
			query {
				news: allStoryblokEntry(
					filter: { field_component: { eq: "common_news" } }
					sort: { order: DESC, fields: [field_date_string] }
				) {
					edges {
						node {
							id
							full_slug
							content
						}
					}
				}
			}
		`,
	)

	const FILTERED_NEWS =
		!isEmpty(news) && news.edges.length
			? news.edges
					.map(({ node }) =>
						Object.assign({}, { ...node }, { content: JSON.parse(node.content) }),
					)
					.filter(item => {
						if (news_category === 'all') {
							return true
						} else {
							return item.content.categories.indexOf(news_category) >= 0
						}
					})
					.slice(0, 6)
			: []

	return (
		<Section>
			<Container>
				<Row>
					<Column>
						<Title>
							<h2>{heading}</h2>
							<p className="lead">{lead}</p>
						</Title>
						<NewsList news={FILTERED_NEWS} />
					</Column>
				</Row>
			</Container>
		</Section>
	)
}

ActualNews.defaultProps = {
	data: {},
}

ActualNews.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		lead: PropTypes.string,
		news_category: PropTypes.string.isRequired,
	}),
}
