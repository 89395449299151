import styled from '@emotion/styled'

import { Column } from 'components/elements/Grid'
import media from 'utils/media'

function setOrder(type) {
	switch (type) {
		case 'left':
			return 1
		case 'right':
			return 2
		default:
			return 2
	}
}

const Content = styled(Column)`
	text-align: center;

	a {
		padding: 20px;
		background-color: white;
		color: ${({ theme }) => theme.colors.footerLink};
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 200px;
		margin: 30px auto 0;
		transition: 0.3s ease-in-out;
		border: 2px solid ${({ theme }) => theme.colors.footerLink};

		svg {
			width: 30px;
			height: 30px;
			fill: ${({ theme }) => theme.colors.footerLink};
			transition: 0.3s ease-in-out;
		}

		&:hover {
			background-color: ${({ theme }) => theme.colors.footerLink};
			color: white;

			svg {
				fill: white;
			}
		}

		${({ type }) => media.greaterThan('lg')`
			margin-left: ${type === 'right' ? 'auto' : 0};
			margin-right: ${type === 'left' ? 'auto' : 0};
		`};
	}

	${({ type }) => media.greaterThan('lg')`
		order: ${setOrder(type)};

		text-align: ${type};
	`};
`

export default Content
