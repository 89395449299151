import styled from '@emotion/styled'

const Socials = styled.div`
	display: flex;

	a {
		width: 50px;
		height: 50px;
		border-radius: 100%;
		background-color: transparent;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.3s ease-in-out;
		border: 2px solid ${({ theme }) => theme.colors.footerLink};

		svg {
			width: 30px;
			height: 30px;
			fill: ${({ theme }) => theme.colors.footerLink};
			transition: 0.3s ease-in-out;
		}

		& + a {
			margin-left: 20px;
		}

		&:hover {
			background-color: ${({ theme }) => theme.colors.footerLink};

			svg {
				fill: white;
			}
		}
	}
`

export default Socials
