import Page from './common/Page'
import News from './common/News'
import TextImage from './TextImage'
import Hero from './Hero'
import Text from './Text'
import NewsSection from './NewsSection'
import TwoColumns from './TwoColumns'
import ActualNews from './ActualNews'
import ContactForm from './ContactForm'

export default {
	common_page: Page,
	common_news: News,
	component_text_image: TextImage,
	component_hero: Hero,
	component_text: Text,
	component_news: NewsSection,
	component_two_columns: TwoColumns,
	component_actual_news: ActualNews,
	component_contact_form: ContactForm,
}
