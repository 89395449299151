import styled from '@emotion/styled'

import media from 'utils/media'

export const Category = styled.span`
	display: block;
	font-weight: bold;
	font-size: 0.875rem;
	text-transform: uppercase;
	color: rgba(0, 124, 137, 0.7);

	${media.greaterThan('md')`
		font-size: 1.2rem;
	`};
`

export const Heading = styled.h1`
	margin-bottom: 10px;

	${media.greaterThan('md')`
		font-size: 3.5rem;
		line-height: 1.2;
	`};
`

export const Info = styled.span`
	display: block;
	margin-bottom: 50px;
	font-weight: bold;
	font-size: 0.875rem;
	color: rgba(0, 124, 137, 0.7);

	${media.greaterThan('md')`
		font-size: 1rem;
	`};
`

export const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;

	img,
	iframe {
		display: block;
		margin: 50px auto;
	}

	iframe {
		width: 100%;
		height: 70vw;
	}

	${media.greaterThan('xs')`
		iframe {
			height: 350px;
		}
	`};

	${media.greaterThan('sm')`
		& > div {
			text-align: justify;
		}

		iframe {
			height: 500px;
		}
	`};

	${media.greaterThan('md')`
		iframe {
			height: 600px;
		}
	`};
`
