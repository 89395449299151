import styled from '@emotion/styled'
import media from 'utils/media'

export const Menu = styled.nav`
	width: 100%;
	height: ${({ open }) => (open ? '100vh' : 0)};
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	transition: height 0.3s ease-in-out;
	background-color: ${({ theme }) => theme.colors.primary};
	overflow: hidden;

	${media.greaterThan('sm')`
		width: calc((8 / 12) * 100%);
		height: auto;
		padding: 0 15px;
		position: static;
		background-color: transparent;
	`}

	${media.greaterThan('md')`
		width: calc((6 / 12) * 100%);
	`}

	ul {
		width: 100%;
		height: 100%;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;

		${media.greaterThan('sm')`
			color: black;
			flex-direction: row;
			justify-content: space-between;
		`}
	}

	li {
		transition: 0.4s ease-out;
		margin-bottom: 30px;

		&:nth-of-type(odd) {
			transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-500px)')};
			transition-delay: 0.3s;
		}

		&:nth-of-type(even) {
			transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(500px)')};
			transition-delay: 0.3s;
		}

		a.link {
			font-size: 2.25rem;

			&.active {
				border-bottom: 2px solid white;
			}
		}

		${({ theme }) => media.greaterThan('sm')`
			transition: none;
			margin-bottom: 0;

			&:nth-of-type(odd),
			&:nth-of-type(even) {
				transform: translateX(0);
			}

			a.link {
				font-size: 1rem;
				line-height: 1;
				padding: 5px 0;
				transition: .2s ease-out;
				font-weight: bold;

				&.active {
					border-bottom: 2px solid ${theme.colors.primary};
					color: ${theme.colors.primary};

				}

				&:hover {
					color: ${theme.colors.primary};
				}
			}
		`}
	}
`

export const SocialWrapper = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: ${({ theme }) => theme.colors.facebook};

	a {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 30px;
			fill: white;
		}
	}

	${media.greaterThan('sm')`
		width: 40px;
		height: 40px;

		a {
			svg {
				width: 20px;
			}
		}
	`}
`
