import React from 'react'

import Components from 'components/'

export default function Page(props) {
	const { blok, pageContext = {} } = props

	return (
		blok.body &&
		blok.body.map(blok => {
			if (Components[blok.component]) {
				return React.createElement(Components[blok.component], {
					key: blok._uid,
					data: blok,
					pageContext,
				})
			} else {
				return (
					<h1 key={blok._uid}>
						Component <span style={{ color: 'red' }}>{blok.component}</span>not created yet
					</h1>
				)
			}
		})
	)
}
