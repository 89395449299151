export const formatDate = date => {
	if (!date) {
		return 'DATA ERROR'
	}

	const parsedDate = date.replace(' ', 'T')
	console.log('🚀 ~ file: helpers.js ~ line 7 ~ parsedDate', parsedDate)
	const currentDateTime = new Date(parsedDate)
	const formattedDate = currentDateTime.toLocaleDateString('pl-PL', {
		day: '2-digit',
		month: 'long',
		year: 'numeric',
	})

	return formattedDate
}
