import styled from '@emotion/styled'
import media from 'utils/media'

const Footer = styled.footer`
	padding: 50px 0 25px 0;
	color: ${({ theme }) => theme.colors.footerText};
	background-color: ${({ theme }) => theme.colors.accent};

	h3 {
		display: inline-block;
		position: relative;
		color: ${({ theme }) => theme.colors.footerLink};
		padding-bottom: 5px;

		${media.greaterThan('md')`
			margin-bottom: 30px;
		`}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 3px;
			background-color: ${({ theme }) => theme.colors.footerLink};
		}
	}

	a {
		color: ${({ theme }) => theme.colors.facebook};
	}
`

export default Footer
