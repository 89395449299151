import React from 'react'
import Markdown from 'markdown-to-jsx'

import TextCenter from '../TextCenter'

export default function MarkdownComponent({ ...rest }) {
	return (
		<Markdown
			options={{
				forceBlock: true,
				overrides: {
					center: {
						component: TextCenter,
					},
				},
			}}
			{...rest}
		/>
	)
}
