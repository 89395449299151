import styled from '@emotion/styled'

const Title = styled.div`
	margin-bottom: 40px;

	p.lead {
		margin: 0;
	}
`

export default Title
