import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Container, Row, Column } from 'components/elements/Grid';
import Markdown from 'components/elements/Markdown';

import Section from './Section';
import Content from './Content';
import Title from './Title';

export default function TwoColumns({ data }) {
	const { heading, lead, left_column, right_column } = data;
	return (
		<Section>
			<Container>
				<Row>
					<Column>
						<Title>
							<h2>{heading}</h2>
							<p className='lead'>{lead}</p>
						</Title>
					</Column>
					<Column width={[ 1, 1, 1, 1 / 2 ]}>
						{!isEmpty(left_column) && (
							<Content>
								<Markdown>{left_column}</Markdown>
							</Content>
						)}
					</Column>
					<Column width={[ 1, 1, 1, 1 / 2 ]}>
						{!isEmpty(right_column) && (
							<Content>
								<Markdown>{right_column}</Markdown>
							</Content>
						)}
					</Column>
				</Row>
			</Container>
		</Section>
	);
}

TwoColumns.defaultProps = {
	data: {}
};

TwoColumns.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		lead: PropTypes.string,
		left_column: PropTypes.string,
		right_column: PropTypes.string
	})
};
