import React from 'react'
import { Box } from 'rebass'

const Container = props => <Box {...props} />

Container.defaultProps = {
	mx: 'auto',
	px: 3,
	maxWidth: ['100%', '540px', '720px', '960px', '1140px'],
}

export default Container
