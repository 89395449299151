import React from 'react'
import styled from '@emotion/styled'

const StyledHamburger = styled.button`
	position: relative;
	z-index: 2;
	display: block;
	cursor: pointer;
	width: 23px;
	height: 26px;
	border: none;
	transition: 0.5s;

	&:before,
	&:after {
		position: absolute;
		content: '';
		top: 50%;
		left: 0;
		width: 100%;
		height: 2px;
		background-color: ${({ open, theme }) => (open ? 'white' : theme.colors.primary)};
	}

	&:before {
		transform: rotate(45deg) scale(${({ open }) => (open ? 1 : 0)});
	}

	&:after {
		transform: rotate(-45deg) scale(${({ open }) => (open ? 1 : 0)});
	}

	div {
		position: absolute;
		right: 0;
		border: ${({ open, theme }) => (open ? 'transparent' : `1px solid ${theme.colors.primary}`)};

		&:nth-of-type(1) {
			width: 100%;
			top: 5px;
		}
		&:nth-of-type(2) {
			width: 80%;
			top: 12px;
		}
		&:nth-of-type(3) {
			width: 100%;
			top: 19px;
		}
	}
`

export default function Hamburger({ open, onClick }) {
	return (
		<StyledHamburger onClick={onClick} open={open} type="button" aria-label="Hamburger Menu">
			<div />
			<div />
			<div />
		</StyledHamburger>
	)
}
