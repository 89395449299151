import styled from '@emotion/styled'

const Copy = styled.div`
	text-align: center;
	font-size: 0.75rem;

	p:last-of-type {
		margin-bottom: 0;
	}
`

export default Copy
