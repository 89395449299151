import styled from '@emotion/styled'

const Navbar = styled.header`
	width: 100%;
	padding: 20px 0;
	background-color: white;
	position: fixed;
	z-index: 10;
`

export default Navbar
