import React from 'react'
import PropTypes from 'prop-types'

import Image from 'components/elements/Image'

import Section from './Section'

export default function Hero({ data }) {
	const { image, text, colored_text } = data
	return (
		<Section>
			{image && <Image fluid data={image} />}
			<div className="text_wrapper">
				<h1>
					{text}
					<br />
					<span>{colored_text}</span>
				</h1>
			</div>
		</Section>
	)
}

Hero.defaultProps = {
	data: {},
}

Hero.propTypes = {
	data: PropTypes.shape({
		image: PropTypes.shape({}),
		text: PropTypes.string,
		colored_text: PropTypes.string,
	}),
}
