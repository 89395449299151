import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { ReactComponent as FacebookIco } from 'assets/svg/facebook-ico.svg'
import { ReactComponent as PaypalIco } from 'assets/svg/paypal-white.svg'

const types = {
	facebook: <FacebookIco />,
	paypal: <PaypalIco />,
}

export default function SocialLink({ type, url, className }) {
	return type && !isEmpty(types[type]) ? (
		<a
			href={url}
			aria-label={type.charAt(0).toUpperCase() + type.slice(1)}
			target={!isEmpty(url) && url.substring(0, 6) === 'mailto' ? '_self' : '_blank'}
			className={className}
		>
			{types[type]}
		</a>
	) : (
		<p>Unknown type</p>
	)
}
