import React, { forwardRef } from 'react'
import { Box } from 'rebass'

const Column = forwardRef((props, ref) => <Box ref={ref} {...props} />)

Column.defaultProps = {
	px: 3,
	width: 1,
}

export default Column
