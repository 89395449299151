import React from 'react'
import { Global, css } from '@emotion/core'

const globalStyles = css`
	@font-face {
		font-family: 'Comfortaa';
		font-style: normal;
		font-display: swap;
		font-weight: normal;
		src: url('/fonts/comfortaa.woff2') format('woff2'),
			url('/fonts/comfortaa.woff') format('woff');
	}

	@font-face {
		font-family: 'RobotoBold';
		font-style: normal;
		font-display: swap;
		font-weight: bold;
		src: url('/fonts/roboto-bold.woff2') format('woff2'),
			url('/fonts/roboto-bold.woff') format('woff');
	}

	@font-face {
		font-family: 'RobotoLight';
		font-style: normal;
		font-display: swap;
		font-weight: normal;
		src: url('/fonts/roboto-light.woff2') format('woff2'),
			url('/fonts/roboto-light.woff') format('woff');
	}

	*,
	*:after,
	*:before {
		box-sizing: border-box;
	}

	body {
		margin: 0;
	}

	button {
		appearance: none;
		margin: 0;
		padding: 0;
		outline: 0;
		background: none;
		border: 0;
		cursor: pointer;
	}

	ul {
		list-style: none;
	}

	a {
		text-decoration: none;
		color: inherit;
	}
`

export default function() {
	return <Global styles={globalStyles} />
}
