import styled from '@emotion/styled'

import media from 'utils/media'

const Section = styled.section`
	padding-top: 50px;
	padding-bottom: 50px;
	text-align: center;

	${media.greaterThan('md')`
		padding-top: 100px;
		padding-bottom: 100px;
	`};
`

export default Section
