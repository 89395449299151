import { css } from '@emotion/core'

function getSizeFromBreakpoint(breakpointValue, breakpoints = {}) {
	if (breakpoints[breakpointValue]) {
		return breakpoints[breakpointValue]
	} else if (parseInt(breakpointValue)) {
		return breakpointValue
	} else {
		console.error('Media-Query: No valid breakpoint or size specified for media.')
		return '0'
	}
}

function generateMedia(breakpoints) {
	const lessThan = breakpoint => (...args) => css`
		@media (max-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
			${css(...args)}
		}
	`

	const greaterThan = breakpoint => (...args) => css`
		@media (min-width: ${getSizeFromBreakpoint(breakpoint, breakpoints)}) {
			${css(...args)}
		}
	`

	const between = (firstBreakpoint, secondBreakpoint) => (...args) => css`
		@media (min-width: ${getSizeFromBreakpoint(
				firstBreakpoint,
				breakpoints,
			)}) and (max-width: ${getSizeFromBreakpoint(secondBreakpoint, breakpoints)}) {
			${css(...args)}
		}
	`

	return Object.assign({
		lessThan,
		greaterThan,
		between,
	})
}

const breakpoints = {
	xs: '576px',
	sm: '768px',
	md: '992px',
	lg: '1200px',
}

const media = generateMedia(breakpoints)

export default media
