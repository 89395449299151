import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper, Navigation, Pagination } from 'swiper/js/swiper.esm'
import 'swiper/css/swiper.css'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import Markdown from 'components/elements/Markdown'
import { formatDate } from 'utils/helpers'
import media from 'utils/media'

const params = {
	Swiper,
	modules: [Navigation, Pagination],
	pagination: {
		el: '.swiper-pagination',
		type: 'bullets',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	spaceBetween: 30,
	breakpoints: {
		992: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 30,
		},
		576: {
			slidesPerView: 1,
		},
	},
}

export default function NewsList({ news }) {
	function handleOnClick(url) {
		navigate(`/${url}/`)
	}

	return (
		<Wrapper>
			<Slider {...params}>
				{news.map(({ id, full_slug, content }) => (
					<Slide key={id}>
						<div onClick={() => handleOnClick(full_slug)}>
							{!isEmpty(content.excerpt) && <Markdown>{content.excerpt}</Markdown>}
							<StyledInfo>
								<span>{formatDate(content.date)}</span>
								<span>{content.author}</span>
							</StyledInfo>
						</div>
					</Slide>
				))}
			</Slider>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	margin-top: 50px;

	.swiper-container {
		padding-bottom: 50px;
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none;
	}

	.swiper-pagination {
		bottom: 0;

		span {
			width: 10px;
			height: 10px;
		}

		.swiper-pagination-bullet-active {
			background: ${({ theme }) => theme.colors.primary};
		}
	}

	${({ theme }) => media.greaterThan('xs')`
		.swiper-button-next,
		.swiper-button-prev {
			display: flex;
			transform: translateY(-50%);
			color: ${theme.colors.primary};
		}
	`};

	${media.greaterThan('sm')`
		.swiper-button-next,
		.swiper-button-prev {
			width: 15px;
			height: 24px;
			top: auto;
			transform: translateY(0);
			bottom: 0;

			&:after {
				font-size: 24px;
				font-weight: bold;
			}
		}

		.swiper-button-next{
			right: 0;
		}
		.swiper-button-prev {
			left: 0;
		}
	`};
`

const Slide = styled.div`
	text-align: center;

	& > div {
		height: 280px;
		max-width: 340px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		outline: none;
		outline-color: transparent;
		padding: 20px;
		border: 2px solid rgba(0, 124, 137, 0.2);
		transition: box-shadow 0.3s ease;

		h4 {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}

		p {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		}
	}
`

const StyledInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: rgba(0, 124, 137, 0.4);
	font-weight: bold;
`

NewsList.propTypes = {
	news: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			full_slug: PropTypes.string.isRequired,
			content: PropTypes.shape({
				date: PropTypes.string.isRequired,
				author: PropTypes.string.isRequired,
				excerpt: PropTypes.string,
			}),
		}),
	),
}
