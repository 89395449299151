import React from 'react'
import { Flex } from 'rebass'

const Row = props => <Flex {...props} />

Row.defaultProps = {
	mx: -3,
	flexWrap: 'wrap',
}

export default Row
