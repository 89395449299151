import React from 'react'
import { Link } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

const LinkComponent = ({ title, type, url, children, activeclassname, className }) => {
	if (type === 'internal') {
		return (
			<Link to={url} activeClassName={activeclassname} className={className}>
				{children}
				{title}
			</Link>
		)
	}
	return (
		<a
			href={url}
			className={className}
			target={!isEmpty(url) && url.substring(0, 6) === 'mailto' ? '_self' : '_blank'}
		>
			{children}
			{title}
		</a>
	)
}

export default LinkComponent
