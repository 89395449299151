import styled from '@emotion/styled'

import media from 'utils/media'

import { Column } from 'components/elements/Grid'

export const Wrapper = styled(Column)`
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	margin-bottom: 50px;

	& > div {
		outline: none;
		outline-color: transparent;
		padding: 20px;
		border: 2px solid rgba(0, 124, 137, 0.2);
		transition: box-shadow 0.3s ease;

		&:hover {
			box-shadow: 0 0 20px 10px rgba(0, 124, 137, 0.2);
			border-color: transparent;
			background-color: rgba(0, 124, 137, 0.2);
		}

		img {
			display: none;
		}
	}

	${media.greaterThan('sm')`
		& > div {
			height: 320px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			h4 {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
			}

			p {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
			}
		}
	`};

	${media.greaterThan('md')`
		& > div {
			height: 370px;

			p {
				-webkit-line-clamp: 6;
			}
		}
	`};
`

export const StyledInfo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: rgba(0, 124, 137, 0.4);
	font-weight: bold;
`
