import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Logo = styled(Link)`
	display: block;

	img {
		margin: 0;
	}
`

export default Logo
