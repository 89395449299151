export default {
	colors: {
		primary: '#23a455',
		accent: '#fcd435',
		text: '#54595f',
		facebook: '#3b5998',
		footerText: '#262626',
		footerLink: '#007c89',
	},
	sizes: {
		auto: 'auto',
	},
	space: [0, 5, 10, 15, 26, 40],
	breakpoints: ['576px', '768px', '992px', '1200px'],
}
