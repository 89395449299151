import React from 'react'
import PropTypes from 'prop-types'

import { Container, Row } from 'components/elements/Grid'
import Link from 'components/elements/Link'
import SocialLink from 'components/elements/SocialLink'
import Img from 'components/elements/Image'

import Section from './Section'
import Image from './Image'
import Content from './Content'

export default function TextImage({ data }) {
	const { heading, lead, text, image, type, link } = data

	function renderLink(data) {
		switch (data.component) {
			case 'element_social_link':
				return <SocialLink {...data} />
			default:
				return <Link {...data} />
		}
	}

	return (
		<Section>
			<Container>
				<Row>
					<Image width={[1, 1, 1, 1, 1 / 2]} type={type}>
						{image && <Img fluid data={image} />}
					</Image>
					<Content width={[1, 1, 1, 1, 1 / 2]} type={type}>
						<h2>{heading}</h2>
						<p className="lead">{lead}</p>
						<p>{text}</p>
						{link && link[0] && renderLink(link[0])}
					</Content>
				</Row>
			</Container>
		</Section>
	)
}

TextImage.defaultProps = {
	data: {},
}

TextImage.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		lead: PropTypes.string,
		text: PropTypes.string,
		type: PropTypes.oneOf(['left', 'right']),
	}),
}
