import styled from '@emotion/styled'

import media from 'utils/media'

const Section = styled.section`
	position: relative;

	.gatsby-image-wrapper {
		${media.greaterThan('md')`
			max-height: 700px;
		`};

		${media.greaterThan('lg')`
			max-height: 850px;
		`};
	}

	div.text_wrapper {
		width: 100%;
		padding: 15px;
		position: absolute;
		left: 0;
		bottom: 50%;
		transform: translateY(50%);
		background-color: rgba(0, 0, 0, 0.6);
		text-align: center;

		h1 {
			margin: 0;
			color: white;

			span {
				position: relative;
				text-transform: uppercase;
				color: ${({ theme }) => theme.colors.accent};
			}
		}

		${media.greaterThan('xs')`
			width: auto;
			padding: 30px;
			text-align: left;

			h1 {
				span {
					display: inline-block;
					margin-top: 10px;
					font-size: 3rem;
				}
			}
		`};

		${media.greaterThan('sm')`
			padding: 50px;
			bottom: 50px;
			transform: translateY(0);

			h1 {
				span {
					margin-top: 10px;
					font-size: 4rem;
				}
			}
		`};

		${media.greaterThan('md')`
			bottom: 100px;

			h1 {
				span {
					font-size: 5rem;
				}
			}
		`};
	}
`

export default Section
