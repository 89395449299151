import styled from '@emotion/styled'

const Title = styled.div`
	text-align: center;

	h2 {
		margin-bottom: 0;
	}
`

export default Title
