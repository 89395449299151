import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Container, Row, Column } from 'components/elements/Grid'
import Image from 'components/elements/Image'
import Link from 'components/elements/Link'
import SocialLink from 'components/elements/SocialLink'

import Navbar from './Navbar'
import { Menu, SocialWrapper } from './Menu'
import Hamburger from './Hamburger'
import Logo from './Logo'

export default function Header({ data }) {
	const [mobileMenu, handleMobileMenu] = useState(false)
	const { logo, menu } = data

	return (
		<Navbar>
			<Container>
				<Row alignItems="center" justifyContent="space-between">
					<Column width={['200px']}>
						<Logo to="/" type="internal" aria-label="Home">
							<Image fluid data={logo} options={{ maxWidth: 200 }} />
						</Logo>
					</Column>
					<Column width={['auto']} sx={{ display: ['block', 'block', 'none'] }}>
						<Hamburger open={mobileMenu} onClick={() => handleMobileMenu(!mobileMenu)} />
					</Column>
					<Menu open={mobileMenu}>
						<ul>
							{menu.map(item =>
								item.component === 'element_link' ? (
									<li key={item._uid}>
										<Link {...item} activeclassname="active" className="link" />
									</li>
								) : (
									<li key={item._uid}>
										<SocialWrapper>
											<SocialLink {...item} />
										</SocialWrapper>
									</li>
								),
							)}
						</ul>
					</Menu>
				</Row>
			</Container>
		</Navbar>
	)
}

Header.defaultProps = {
	data: {
		menu: [],
	},
}

Header.propTypes = {
	data: PropTypes.shape({
		menu: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				type: PropTypes.string.isRequired,
				title: PropTypes.string,
				component: PropTypes.string,
			}),
		),
	}),
}
