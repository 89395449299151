import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { Container, Row, Column } from 'components/elements/Grid'
import Markdown from 'components/elements/Markdown'
import { formatDate } from 'utils/helpers'

import Section from './Section'
import { Wrapper, StyledInfo } from './News'

export default function NewsSection({ data }) {
	const { title, description, news_category } = data
	const { news } = useStaticQuery(
		graphql`
			query {
				news: allStoryblokEntry(
					filter: { field_component: { eq: "common_news" } }
					sort: { order: DESC, fields: [field_date_string] }
				) {
					edges {
						node {
							id
							full_slug
							content
						}
					}
				}
			}
		`,
	)

	const FILTERED_NEWS =
		!isEmpty(news) && news.edges.length
			? news.edges
					.map(({ node }) =>
						Object.assign({}, { ...node }, { content: JSON.parse(node.content) }),
					)
					.filter(item => {
						if (news_category === 'all') {
							return true
						} else {
							return item.content.categories.indexOf(news_category) >= 0
						}
					})
			: []

	function handleOnClick(url) {
		navigate(`/${url}/`)
	}

	return (
		<Section>
			<Container>
				<Row>
					<Column>
						<h2>{title}</h2>
						<p>{description}</p>
						<Row my={[20]} py={[20]}>
							{FILTERED_NEWS.map(({ id, full_slug, content }) => (
								<Wrapper
									width={[1, 1, 1 / 2, 1 / 3]}
									key={id}
									onClick={() => handleOnClick(full_slug)}
								>
									<div>
										{!isEmpty(content.excerpt) && <Markdown>{content.excerpt}</Markdown>}
										<StyledInfo>
											<span>{formatDate(content.date)}</span>
											<span>{content.author}</span>
										</StyledInfo>
									</div>
								</Wrapper>
							))}
						</Row>
					</Column>
				</Row>
			</Container>
		</Section>
	)
}

NewsSection.defaultProps = {
	data: {},
}

NewsSection.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		news_category: PropTypes.string.isRequired,
	}),
}
