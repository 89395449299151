import styled from '@emotion/styled'

const Address = styled.div`
	letter-spacing: 0.4px;

	p:last-of-type {
		margin-bottom: 0;
	}
`

export default Address
