import React from 'react'
import PropTypes from 'prop-types'

import { Container, Row, Column } from 'components/elements/Grid'
import Markdown from 'components/elements/Markdown'
import { formatDate } from 'utils/helpers'
import Section from './Section'
import RecentNews from './RecentNews'
import { Category, Heading, Info, ContentWrapper } from './Content'

function translateCategories(categories) {
	const translations = {
		news: 'Aktualności',
		action: 'Akcja',
		project: 'Projekt',
	}
	const translatedCategories = categories.map(category => translations[category])
	return translatedCategories.join(' | ')
}
export default function News({ blok, recentNews }) {
	console.log('News -> recentNews', recentNews)
	const { date, author, main_content, categories, title } = blok
	return (
		<Section>
			<Container>
				<Row>
					<Column>
						<Category>{translateCategories(categories)}</Category>
						<Heading>{title}</Heading>
						<Info>
							Opublikował {author} w dniu {formatDate(date)}
						</Info>
						<ContentWrapper>
							{main_content && <Markdown>{main_content}</Markdown>}
						</ContentWrapper>
					</Column>
					<Column>
						<RecentNews posts={recentNews} />
					</Column>
				</Row>
			</Container>
		</Section>
	)
}

News.defaultProps = {
	recentNews: [],
	blok: {
		categories: [],
	},
}

News.propTypes = {
	blok: PropTypes.shape({
		date: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		author: PropTypes.string.isRequired,
		categories: PropTypes.arrayOf(PropTypes.string).isRequired,
		main_content: PropTypes.string,
	}),
	recentNews: PropTypes.arrayOf(
		PropTypes.shape({
			slug: PropTypes.string.isRequired,
			content: PropTypes.shape({
				date: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired,
				author: PropTypes.string.isRequired,
				categories: PropTypes.arrayOf(PropTypes.string).isRequired,
				main_content: PropTypes.string,
			}),
		}),
	).isRequired,
}
