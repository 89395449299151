import React from 'react'
import PropTypes from 'prop-types'

import Markdown from 'components/elements/Markdown'
import SocialLink from 'components/elements/SocialLink'
import { Container, Row, Column } from 'components/elements/Grid'

import Footer from './Footer'
import Socials from './Socials'
import Copy from './Copy'
import Contact from './Contact'
import Address from './Address'

export default function FooterComponent({ data }) {
	const { address, address_title, socials, socials_title, contact, contact_title, copy } = data
	return (
		<Footer>
			<Container>
				<Row justifyContent={[null, null, 'space-between']}>
					<Column mb={[50, 50, 50, 0]} width={[1, 1, '48%', '37%']}>
						<h3>{address_title}</h3>
						<Address>{address && <Markdown>{address}</Markdown>}</Address>
					</Column>
					<Column mb={[50, 50, 50, 0]} width={[1, 1, '48%', '30%']}>
						<h3>{contact_title}</h3>
						<Contact>{contact && <Markdown>{contact}</Markdown>}</Contact>
					</Column>
					<Column mb={[50, 50, 50, 0]} width={[1, 1, '40%', '15%']}>
						<h3>{socials_title}</h3>
						<Socials>
							{socials.map(item => (
								<SocialLink key={item._uid} {...item} />
							))}
						</Socials>
					</Column>
					{copy && (
						<Column mt={[0, 0, 0, 100]}>
							<Copy>
								<Markdown>{copy}</Markdown>
							</Copy>
						</Column>
					)}
				</Row>
			</Container>
		</Footer>
	)
}

FooterComponent.defaultProps = {
	data: {
		socials: [],
	},
}

FooterComponent.propTypes = {
	data: PropTypes.shape({
		address_title: PropTypes.string,
		address: PropTypes.string,
		socials_title: PropTypes.string,
		socials: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string.isRequired,
				type: PropTypes.string.isRequired,
			}),
		),
		contact_title: PropTypes.string,
		contact: PropTypes.string,
		copy: PropTypes.string,
	}),
}
