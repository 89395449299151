import styled from '@emotion/styled';

const Content = styled.div`
	margin-top: 30px;

	hr {
		margin: 30px 0;
		border-bottom: 1px solid ${({ theme }) => theme.colors.footerLink};
		background: transparent;
	}
`;

export default Content;
