import styled from '@emotion/styled'

const Content = styled.div`
	a {
		display: inline-block;
		margin-top: 30px;
		color: ${({ theme }) => theme.colors.footerLink};
		font-weight: bold;
		transition: 0.3s ease-in;
		position: relative;
		padding: 5px 0;

		&:after {
			content: '';
			width: 0;
			height: 2px;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: ${({ theme }) => theme.colors.footerLink};
			transition: 0.2s ease-in-out;
		}
		&:hover {
			&:after {
				width: 100%;
			}
		}
	}
`

export default Content
